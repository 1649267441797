import React, { useEffect, useContext } from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import MethodGet from "../../config/Service";
export default function SelectCategory(props) {
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    let url = "/Categories";
    MethodGet(url)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const detectarCambiosCategory = (e) => {
    props.detectarCambiosCategory(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Categorias</InputLabel>
          {props.categoryId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosCategory}
              defaultValue={props.categoryId}
            >
              {categories &&
                categories.map((categories) => (
                  <MenuItem key={categories.id} value={categories.id}>
                    {categories.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosCategory}
            >
              {categories &&
                categories.map((categories) => (
                  <MenuItem key={categories.id} value={categories.id}>
                    {categories.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
