import React from "react";
import {
  ADD_PRODUCT_CART,
  DELETE_PRODUCT,
  GET_CART_USER,
  GET_DISCOUNT,
  GET_TOTAL_CART,
  INCREASE_QUANTITY_PRODUCT_CART,
  MODIFY_QUANTITY_PRODUCT,
  REDUCE_QUANTITY_PRODUCT_CART,
  REMOVE_PRODUCT_CART,
} from "../../types";

export default function CartReducer(state, action) {
  switch (action.type) {
    case GET_CART_USER:
      return {
        ...state,
        cart: action.payload,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_TOTAL_CART:
      return {
        ...state,
        totalCart: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_DISCOUNT:
      return {
        ...state,
        discount: action.payload.discount,
        id_business: action.payload.id,
        success: false,
        ErrorsApi: [],
      };

    case ADD_PRODUCT_CART:
      return {
        ...state,
        cart: action.payload,
        success: true,
        ErrorsApi: [],
      };
    case REDUCE_QUANTITY_PRODUCT_CART:
      return {
        ...state,
        cart: state.cart.map((cartItem) =>
          cartItem.id === action.payload.product.id
            ? { ...cartItem, ...action.payload.product }
            : cartItem
        ),
        success: true,
        ErrorsApi: [],
      };
    case INCREASE_QUANTITY_PRODUCT_CART:
      return {
        ...state,
        cart: state.cart.map((cartItem) =>
          cartItem.id === action.payload.id
            ? { ...cartItem, ...action.payload.product }
            : cartItem
        ),
        success: true,
        ErrorsApi: [],
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        success: true,
        cart: state.cart.filter((product) => product.id !== action.payload),
        ErrorsApi: [],
      };
    case MODIFY_QUANTITY_PRODUCT:
      return {
        ...state,
        success: true,
        cart: state.cart.map((cartItem) =>
          cartItem.id === action.payload.id
            ? { ...cartItem, ...action.payload }
            : cartItem
        ),
        success: true,
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
