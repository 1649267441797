import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import OrdersContext from "../../../Context/Orders/OrdersContext";
import { useContext } from "react";

export default function ModalShipping({ order_id, open, handleClose }) {
  const { MakeAsReady } = useContext(OrdersContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("no_guide", "", { shouldDirty: true });
  };
  const onSubmit = (data, e) => {
    data.id = order_id;
    if (data !== "") {
      MakeAsReady(data);
      reset();
      handleClose();
    } else {
      Swal.fire({
        title: "error",
        text: "Todos los campos son obligatorios",
        icon: "error",
      });
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Enviar pedido #${order_id} `}</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
        >
          <DialogContent>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Número de guia"
                  placeholder="4656453168"
                  variant="outlined"
                  name="no_guide"
                  type="text"
                  error={errors.no_guide ? true : false}
                  helperText={errors?.no_guide?.message}
                  {...register("no_guide", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 1,
                      message: "Minimo 1 caracter",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button type="submit" autoFocus color="success">
              Enviar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
