import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CardProduct from "../../components/Products/CardProduct";
import Swal from "sweetalert2";
import ProductsContext from "../../Context/Products/ProductsContext";
import { useContext } from "react";
import { useDebounce } from "use-debounce";
import SearchingComponent from "../../components/loading/SearchingComponent";
import NoDataComponent from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/styles";
import CartContext from "../../Context/Cart/CartContext";
import Layout from "../../components/Layout/Layout";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#092635",
    borderRadius: "25px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#092635",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#092635",
      borderRadius: "25px",
    },
    "&:hover fieldset": {
      borderColor: "#092635",
      borderRadius: "25px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#092635",
      borderRadius: "25px",
    },
  },
});
const Index = () => {
  const [spinner, setSpinerCargando] = useState(false);
  const { GetAllProducts, products } = useContext(ProductsContext);
  useEffect(() => {
    setSpinerCargando(true);
    GetAllProducts();
  }, []);
  const { GetCartUserLogged, cart } = useContext(CartContext);
  useEffect(() => {
    GetCartUserLogged();
  }, []);

  const [productsWithQuantity, setProductsWithQuantity] = useState(
    products
      ? products.map((product) => {
          const cartItem = cart.find((item) => item.id === product.id);
          const quantity = cartItem ? cartItem.quantity : 0;
          return { ...product, quantity };
        })
      : []
  );

  //funcion para buscador
  const [magicWord, setMagicWord] = useState("");
  const [debounceWord] = useDebounce(magicWord, 1500);
  const [searchProducts, saveSearchProducts] = useState([]);

  const handleSearchProduct = () => {
    try {
      let searchResult = productsWithQuantity.filter((product) =>
        product.name
          .toString()
          .toLowerCase()
          .includes(debounceWord.toLowerCase())
      );
      saveSearchProducts(searchResult);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se encontraron registros que coincidan con los criterios de búsqueda!",
        icon: "error",
      });
    }
  };
  useEffect(() => {
    setSpinerCargando(true);
    setProductsWithQuantity(
      products.map((product) => {
        const cartItem = cart.find((item) => item.id === product.id);
        const quantity = cartItem ? cartItem.quantity : 0;
        return { ...product, quantity };
      })
    );
  }, [cart, products]);
  useEffect(() => {
    setSpinerCargando(true);
    handleSearchProduct();
  }, [debounceWord, productsWithQuantity]);

  return (
    <Layout>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        {productsWithQuantity.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 2 }}>
            <CssTextField
              fullWidth
              label="Buscar producto por nombre"
              // type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#092635" }} />
                  </InputAdornment>
                ),
              }}
              name="search"
              onChange={(e) => {
                setMagicWord(e.target.value);
                saveSearchProducts(null);
              }}
            />
          </Grid>
        )}
      </Grid>
      {!magicWord &&
        (!productsWithQuantity.length ? (
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <NoDataComponent />
          </div>
        ) : (
          <Grid container spacing={1} sx={{ padding: 2 }}>
            {productsWithQuantity.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CardProduct product={product} />
              </Grid>
            ))}
          </Grid>
        ))}
      {magicWord &&
        (!searchProducts ? (
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <SearchingComponent />
          </div>
        ) : searchProducts.length === 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <NoResultsComponent />
          </div>
        ) : (
          <Grid container spacing={1} sx={{ padding: 2 }}>
            {searchProducts.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CardProduct product={product} />
              </Grid>
            ))}
          </Grid>
        ))}
    </Layout>
  );
};

export default Index;
