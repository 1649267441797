import { Route, Switch } from "react-router-dom";
import Products from "../containers/Products/Index";
import Categories from "../containers/Categorias/Categories";
import IndexCategoriesAdmin from "../containers/Categorias/CategoriasAdmin/IndexCategoriasAdmin";
import IndexProductsAdmin from "../containers/Products/ProductsAdmin/IndexProductsAdmin";
import AyudaAdmin from "../containers/Ayuda/AyudaAdmin/IndexAyudaAdmin";
import OrdersAdmin from "../containers/Orders/OrdersAdmin/IndexOrdersAdmin";
import InicioAdmin from "../containers/Admin/InicioAdmin";
import Ayuda from "../containers/Ayuda/Ayuda";
import Directions from "../containers/Directions/Directions";
import Orders from "../containers/Pedidos/Orders";
import ProductsList from "../containers/ProductsList/ProductsList";
import EditarProduct from "../containers/Products/ProductsAdmin/EditarProduct";
import ShowOrder from "../containers/Control/ShowOrder";
import ProductsCategory from "../containers/Categorias/ProductsCategory";
import StoreProduct from "../containers/Products/ProductsAdmin/StoreProduct";
export const EcommerceRoutes = () => {
  const type_user = localStorage.getItem("type_user");
  let component = Products;

  if (type_user == 0) {
    component = InicioAdmin;
  }

  return (
    <Switch>
      {/**Rutas Admin */}
      <Route exact path="/inicio-admin" component={InicioAdmin} />
      <Route exact path="/categorias-admin" component={IndexCategoriesAdmin} />
      <Route exact path="/productos-admin" component={IndexProductsAdmin} />
      <Route exact path="/ayuda-admin" component={AyudaAdmin} />
      <Route exact path="/ventas-admin" component={OrdersAdmin} />
      <Route exact path="/Editar-producto/:id" component={EditarProduct} />
      <Route exact path="/Registrar-producto" component={StoreProduct} />
      {/**Rutas suario */}
      <Route exact path="/" component={component} />
      <Route exact path="/categorias" component={Categories} />
      <Route exact path="/direcciones" component={Directions} />
      <Route exact path="/pedidos" component={Orders} />
      <Route exact path="/ayuda" component={Ayuda} />
      <Route exact path="/Carrito" component={ProductsList} />
      <Route exact path="/Detalle-venta/:id" component={ShowOrder} />
      <Route
        exact
        path="/productos/:category/:id"
        component={ProductsCategory}
      />

      {/* <Route path="*" component={NotFoundComp onent} /> */}
    </Switch>
  );
};
