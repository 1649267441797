import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { makeStyles } from "@mui/styles";
import PedidosContext from "../../Context/Pedidos/PedidosContext";
import { useContext } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
const useStyles = makeStyles({
  input: {
    display: "none",
  },
  logoimagen: {
    height: "400px",
    width: "400px",
    objectFit: "cover",
  },
});
export default function FormDialog({ open, handleClose, order_id, total }) {
  const classes = useStyles();
  const { UploadTicket } = useContext(PedidosContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [image, setImage] = useState({
    urlPhoto:
      "https://www.pacifictrellisfruit.com/wp-content/uploads/2016/04/default-placeholder-300x300.png",
    image: "",
  });
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };
  const reset = () => {
    setValue("reference", "", { shouldDirty: true });
    setValue("amount", "", { shouldDirty: true });
    setValue("image", "", { shouldDirty: true });
    setImage({
      urlPhoto:
        "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
      image: "",
    });
  };
  const onSubmit = (data, e) => {
    data.image = image.image;
    data.id_sale = order_id;
    if (data !== "") {
      UploadTicket(data);
      reset();
      handleClose();
    } else {
      Swal.fire({
        title: "error",
        text: "Todos los campos son obligatorios",
        icon: "error",
      });
    }
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subir comprobante</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
        >
          <DialogContent>
            {/* <DialogContentText>
              En esta ventana subiras tu comprobante de transferencia junto con
              algunos datos adicionales que se solicitaran
            </DialogContentText> */}
            <Typography fontWeight="bold" textAlign="center" fontSize="25px">
              Total A pagar: ${total}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <img
                      src={image.urlPhoto}
                      className={classes.logoimagen}
                      alt="agrega evidencia"
                    />
                  </div>
                </Box>
                <input
                  className={classes.input}
                  id="icon-button-file-first"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  Selecciona imagen :
                  <label htmlFor="icon-button-file-first">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen"
                        placement="top"
                      >
                        <PhotoCameraIcon />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Folio de transaccion"
                  placeholder="4656453168"
                  variant="outlined"
                  name="reference"
                  type="text"
                  error={errors.reference ? true : false}
                  helperText={errors?.reference?.message}
                  {...register("reference", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 1,
                      message: "Minimo 1 caracter",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Monto depositado"
                  placeholder="1500"
                  variant="outlined"
                  name="amount"
                  type="text"
                  error={errors.amount ? true : false}
                  helperText={errors?.amount?.message}
                  {...register("amount", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 1,
                      message: "Minimo 1 caracteres",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                    pattern: {
                      value: /^[-+]?\d*\.?\d+$/,
                      message: "El campo debe ser numerico",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancelar
            </Button>
            <Button type="submit" color="success">
              Subir comprobante
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
