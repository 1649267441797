import React, { useContext, useEffect } from "react";
import ProductsContext from "../../Context/Products/ProductsContext";
import Layout from "../../components/Layout/Layout";
import { Grid } from "@mui/material";
import CardProduct from "../../components/Products/CardProduct";
const ProductsCategory = (props) => {
  const { category, id } = props.match.params;
  const { ProductsCategory, products } = useContext(ProductsContext);
  useEffect(() => {
    ProductsCategory(id);
  }, [id]);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {products &&
          products.map((p) => (
            <Grid item xs={12} sm={6} ms={4} lg={3} xl={3} key={p.id}>
              <CardProduct product={p} />
            </Grid>
          ))}
      </Grid>
    </Layout>
  );
};

export default ProductsCategory;
