import {
  ADD_MULTIMEDIA_PRODUCT,
  ADD_PRODUCT,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_PAGINATION,
  GET_PRODUCTS_WITHOUT_IMAGE,
  GET_PRODUCTS_WITH_STOCK_LOW,
  PRODUCTS_CATEGORY,
  PRODUCTS_NO_AUTH,
  UPDATE_PRODUCT,
} from "../../types";

export default function ProductsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PRODUCTS_CATEGORY:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_PRODUCTS_PAGINATION:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_PRODUCTS_WITHOUT_IMAGE:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_PRODUCTS_WITH_STOCK_LOW:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PRODUCTS_NO_AUTH:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_PRODUCT:
      return {
        ...state,
        products: [action.payload, ...state.products],
        success: false,
        ErrorsApi: [],
      };
    case ADD_MULTIMEDIA_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id ? { ...action.payload } : product
        ),
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === action.payload.id ? { ...action.payload } : product
        ),
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
