import React from "react";
import Layout from "../../../components/Layout/Layout";
import { Button, Grid, Typography } from "@mui/material";

const IndexAyudaAdmin = () => {
  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 1 }}>
        <Grid item xs={10} sx={10} md={10} lg={10} xl={10}>
          <Typography textAlign="center" fontWeight="bold" fontSize="40px">
            Ayuda
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#E4097F",
              color: "white",
              "&:hover": { backgroundColor: "#E4097F", color: "white" },
            }}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IndexAyudaAdmin;
