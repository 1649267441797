export const types = {
  MOSTRAR_ALERTA: "[AUTH] mostrar alerta",
  OCULTAR_ALERTA: "[AUTH] ocultar alerta",
  LOGIN_ERROR: "[AUTH] login error",
  LOGIN_EXITOSO: "[AUTH] login existoso",
  REGISTRO_EXITOSO: "[AUTH] registro exitoso",
  REGISTRO_ERROR: "[AUTH registro error]",
  OBTENER_USUARIO: "[AUTH] obtener usuario",
  CERRAR_SESION: "[AUTH] cerrar sesion",
  INICIAR_SESION: "[AUTH] inicia sesion",
  USER_CHANGEPHOTO: "[AUTH] USER_CHANGEPHOTO",
};

export const GET_ALL_ORDER = "GET_ALL_ORDERS";
export const MAKE_AS_READY = "MAKE_AS_READY";
export const CREATE_ORDER = "CREATE_ORDER";
export const APROVE_ORDER = "APROVE_ORDER";
export const TAKE_ORDER = "TAKE_ORDER";
export const GET_ALL_ORDERS_COMPLETED = "GET_ALL_ORDER_COMPLETED";
export const GET_ALL_ORDERS_PREPARING = "GET_ALL_ORDERS_PREPARING";
export const GET_ALL_ORDERS_SHIPPING = "GET_ALL_ORDERS_SHIPPING";
export const GET_ALL_ORDERS_PENDING = "GET_ALL_ORDERS_PENDING";
export const GET_ALL_ORDERS_APROVE = "GET_ALL_ORDERS_APROVE";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_PAGINATION = "GET_ALL_PRODUCTS_PAGINATION";
export const GET_PRODUCTS_WITHOUT_IMAGE = "GET_PRODUCTS_WITHOUT_IMAGE";
export const GET_PRODUCTS_WITH_STOCK_LOW = "GET_PRODUCTS_WITH_STOCK_LOW";
export const PRODUCTS_NO_AUTH = "PRODUCTS_NO_AUTH";
export const ADD_MULTIMEDIA_PRODUCT = "ADD_MULTIMEDIA_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const PRODUCTS_CATEGORY = "PRODUCTS_CATEGORY";

export const GET_CART_USER = "GET_CART_USER";
export const ADD_PRODUCT_CART = "ADD_PRODUCT_CART";
export const REDUCE_QUANTITY_PRODUCT_CART = "REDUCE_QUANTITY_PRODUCT_CART";
export const INCREASE_QUANTITY_PRODUCT_CART = "INCREASE_QUANTITY_PRODUCT_CART";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_QUANTITY_PRODUCT = "MODIFY_QUANTITY_PRODUCT";
export const GET_TOTAL_CART = "GET_TOTAL_CART";
export const GET_DISCOUNT = "GET_DISCOUNT";

/**Categorias */
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const STORE_CATEGORY = "STORE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

/**Subcategorias */
export const GET_ALL_SUBCATEGORIES = "GET_ALL_SUBCATEGORIES";
export const STORE_SUBCATEGORY = "STORE_SUBCATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";

/***Ordenes por usuario */
export const GET_ALL_ORDERS_PENDING_USER = "GET_ALL_ORDERS_PENDING_USER";
export const GET_ALL_ORDERS_COMPLETED_USER = "GET_ALL_ORDERS_COMPLETED_USER";
export const UPLOAD_TICKET = "UPLOAD_TICKET";
/**AYUDA */
export const GET_ALL_HELP = "GET_ALL_HELP";
/**direcciones */
export const GET_ALL_DIRECTIONS_USER = "GET_ALL_DIRECTIONS_USER";
export const STORE_DIRECTION = "STORE_DIRECTION";
export const UPDATE_DIRECTION = "UPDATE_DIRECTION";
export const DELETE_DIRECTION = "DELETE_DIRECTION";
//Errors API
export const SHOW_ERRORS_API = "SHOW_ERRORS_API";
