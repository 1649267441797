import React from "react";
import OrdersContext from "../../../Context/Orders/OrdersContext";
import { useContext } from "react";
import { useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoDataComponents from "../../../components/loading/NoDataComponents";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const IndexPendingVerification = () => {
  const { orders, GetAllOrdersPending, AproveOrder } =
    useContext(OrdersContext);
  useEffect(() => {
    GetAllOrdersPending();
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        {orders.length > 0 ? (
          <>
            {orders.map((order) => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card>
                  <CardContent>
                    <Typography>Folio:{order.id}</Typography>
                    <Typography>Client:{order.name}</Typography>
                    <Typography>Telefono:{order.number_phone}</Typography>
                    <Typography>Total: $ {order.total}</Typography>
                  </CardContent>
                  <CardActions>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Link to={`Detalle-venta/${order.id}`}>
                          <IconButton>
                            <Tooltip title="Detalle venta" placement="top">
                              <RemoveRedEyeIcon sx={{ color: "#3f51b5" }} />
                            </Tooltip>
                          </IconButton>
                        </Link>
                        <IconButton onClick={() => AproveOrder(order.id)}>
                          <Tooltip title="Aprobar pago" placement="top">
                            <CheckCircleOutlineIcon sx={{ color: "green" }} />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          <Grid item xs={12} sm={12} lg={12} xl={12} md={12}>
            <NoDataComponents />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default IndexPendingVerification;
