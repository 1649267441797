import React, { useReducer } from "react";
import CategoriesReducer from "./CategoriesReducer";
import {
  DELETE_CATEGORY,
  GET_ALL_CATEGORIES,
  INDEX_CATEGORIES,
  STORE_CATEGORY,
  UPDATE_CATEGORY,
} from "../../types";
import CategoriesContext from "./CategoriesContext";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import imageHeaders from "../../config/imageHeaders";
import Swal from "sweetalert2";
const CategoriesState = ({ children }) => {
  const initialState = {
    categories: [],
    success: false,
    ErrorsApi: [],
  };
  const [state, dispatch] = useReducer(CategoriesReducer, initialState);
  const GetCategories = () => {
    let url = "/Categories";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_CATEGORIES,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const storeCategory = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("image", data.image);
    let url = "/Categories";
    MethodPost(url, formData, { imageHeaders })
      .then((res) => {
        dispatch({
          type: STORE_CATEGORY,
          payload: res.data,
        });
        Swal.fire({
          title: "Creada",
          text: "La categoria se ha creado correctamente!",
          icon: "success",
          timer: 1700,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateCategory = (data) => {
    let url = `/Categories/${data.id}`;
    MethodPut(url, data, { imageHeaders })
      .then((res) => {
        dispatch({
          type: UPDATE_CATEGORY,
          payload: res.data,
        });
        Swal.fire({
          title: "Actualizada",
          text: "La categoria se ha actualizado correctamente!",
          icon: "success",
          timer: 1700,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const DeleteCategory = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La categoria seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/Categories/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_CATEGORY,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <CategoriesContext.Provider
      value={{
        categories: state.categories,
        success: state.success,
        ErrorsApi: state.ErrorsApi,
        GetCategories,
        storeCategory,
        updateCategory,
        DeleteCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesState;
