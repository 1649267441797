import React, { useEffect, useContext } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import MethodGet from "../../config/Service";
export default function SelectSubcategory(props) {
  const [subcategories, setSubcategories] = useState(null);

  useEffect(() => {
    let url = "/Subcategories";
    MethodGet(url)
      .then((res) => {
        setSubcategories(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const detectarCambiosSubcategory = (e) => {
    props.detectarCambiosSubcategory(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Subcategorias</InputLabel>
          {props.subcategoryId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Subcategorias"
              onChange={detectarCambiosSubcategory}
              defaultValue={props.subcategoryId}
            >
              {subcategories &&
                subcategories.map((subcategories) => (
                  <MenuItem key={subcategories.id} value={subcategories.id}>
                    {subcategories.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Subcategorias"
              onChange={detectarCambiosSubcategory}
            >
              {subcategories &&
                subcategories.map((subcategories) => (
                  <MenuItem key={subcategories.id} value={subcategories.id}>
                    {subcategories.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
