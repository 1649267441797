import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ProductsContext from "../../../Context/Products/ProductsContext";
import CardProductAdmin from "../../../components/Products/CardProductAdmin";
import { Link } from "react-router-dom";
const AllProductsAdmin = () => {
  const { GetAllProducts, products } = useContext(ProductsContext);
  useEffect(() => {
    GetAllProducts();
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ display: "flex", justifyContent: "end", margin: 2 }}
      >
        <Link to={"/Registrar-producto"}>
          <Button variant="contained">Agregar</Button>
        </Link>
      </Grid>
      {products.map((product, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={4}
          xl={3}
          key={index}
          justifyContent="center"
        >
          <CardProductAdmin product={product} />
        </Grid>
      ))}
    </Grid>
  );
};

export default AllProductsAdmin;
