import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PublicRouter } from "./PublicRoute";
import { PrivateRouter } from "./PrivateRoute";
import LogIn from "../components/Auth/LogIn";
import Register from "../components/Auth/Register";
import AuthContext from "../Context/auth/AuthContext";
import { EcommerceRoutes } from "./EcommerceRoutes";
import { Box, Grid } from "@mui/material";
import SpinnerComponent from "../components/loading/SpinnerComponent";
import Catalogo from "../containers/Catalogo/Catalogo";
import SpinnerComponentSanhoo from "../components/loading/SpinnerComponentSanhoo";
// import TypeUsers from "../containers/type_users/TypeUsers";

export default function AppRouter() {
  const { autenticado, usuarioAutenticado, cargando, usuario } =
    useContext(AuthContext);

  useEffect(() => {
    usuarioAutenticado();
  }, []);
  if (cargando) {
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            width: "105%",
            height: "177%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SpinnerComponentSanhoo />
        </Box>
      </Grid>
    );
  }
  return (
    <>
      <Router>
        <Switch>
          <PublicRouter
            exact
            path="/registro"
            component={Register}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path="/iniciar-sesion"
            component={LogIn}
            isAuthenticated={autenticado}
          />
          <PublicRouter
            exact
            path="/catalogo"
            component={Catalogo}
            isAuthenticated={autenticado}
          />
          <PrivateRouter
            path="/"
            component={EcommerceRoutes}
            isAuthenticated={autenticado}
          />
        </Switch>
      </Router>
    </>
  );
}
