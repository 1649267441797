import {
  APROVE_ORDER,
  GET_ALL_ORDER,
  GET_ALL_ORDERS_APROVE,
  GET_ALL_ORDERS_COMPLETED,
  GET_ALL_ORDERS_PENDING,
  GET_ALL_ORDERS_PREPARING,
  GET_ALL_ORDERS_SHIPPING,
  MAKE_AS_READY,
  TAKE_ORDER,
} from "../../types";

export default function OrdersReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ORDER:
      return {
        ...state,
        orders: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_PREPARING:
      return {
        ...state,
        orders: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_PENDING:
      return {
        ...state,
        orders: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_APROVE:
      return {
        ...state,
        orders: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_SHIPPING:
      return {
        ...state,
        orders: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_COMPLETED:
      return {
        ...state,
        ordersCompleted: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case MAKE_AS_READY:
      return {
        ...state,
        orders: state.orders.filter((order) => order.id !== action.payload),
      };
    case APROVE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order.id !== action.payload),
      };
    case TAKE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order.id !== action.payload),
      };
    default:
      return state;
  }
}
