import React from "react";
import Layout from "../../../components/Layout/Layout";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import PreparingIndex from "./PreparingIndex";
import SendIndex from "./SendIndex";
import IndexPendingVerification from "./IndexPendingVerification";
import IndexVerificate from "./IndexVerificate";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IndexOrdersAdmin = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 1 }}>
        <Grid item xs={12} sx={12} md={12} lg={12} xl={12}>
          <Typography textAlign="center" fontWeight="bold" fontSize="40px">
            Ordenes
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 1 }}>
          <Box sx={{ width: "90%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Ventas pendientes de verificacion"
                  {...a11yProps(0)}
                />
                <Tab label="Ventas Verificadas" {...a11yProps(1)} />
                <Tab label="Ventas en preparación" {...a11yProps(2)} />
                <Tab label="Ventas Enviadas" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <IndexPendingVerification />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <IndexVerificate />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <PreparingIndex />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <SendIndex />
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IndexOrdersAdmin;
