import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../Context/Cart/CartContext";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import OrdersContext from "../../Context/Orders/OrdersContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import MethodGet from "../../config/Service";
import SelectDirection from "../../containers/SelectOptions/DirectionSelect";
import AddDirection from "../../containers/Directions/AddDirection";
import TextoCorto from "../../utils/TextoCorto";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 3px",
    borderRadius: "10px",
  },
});
const TableProductsCart = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    GetCartUserLogged,
    cart,
    ModifyQuantityProduct,
    GetTotalCart,
    totalCart,
    DeleteProductCart,
  } = useContext(CartContext);
  const [spinnerCargando, setSpinnerCargando] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [id_business, setIdBusiness] = useState(null);
  const [total, setTotal] = useState(0);
  const [direction, setDirection] = useState(null);
  const detectarCambiosDirection = (e) => {
    setDirection(e.target.value);
  };

  useEffect(() => {
    GetCartUserLogged();
  }, []);

  const handleChangeQuantity = async (e, id) => {
    const data = {};
    data.product_id = id;
    data.quantity = Number(e.target.value);

    await ModifyQuantityProduct(data);
  };

  const handleClickDelete = async (id) => {
    setSpinnerCargando(true);
    await DeleteProductCart(id);
  };

  const subtotal = cart.map((c) => c.quantity * c.price);
  var sumaSubtotal = subtotal.reduce(function (acc, numero) {
    return acc + numero;
  }, 0);

  useEffect(() => {
    let url = `/Discount/${sumaSubtotal}`;
    MethodGet(url)
      .then((res) => {
        setDiscount(res.data.discount);
        setIdBusiness(res.data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sumaSubtotal]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const calculateTotal = (sumaSubtotal, discount) => {
    setTotal(sumaSubtotal * (1 - discount / 100));
  };
  console.log(direction);
  useEffect(() => {
    calculateTotal(sumaSubtotal, discount);
  }, [sumaSubtotal, cart, discount]);
  const { CreateOrder } = useContext(OrdersContext);
  const onSubmit = (data) => {
    data.status = 0;
    data.subtotal = sumaSubtotal;
    data.id_business_rule = id_business;
    data.total = total;
    data.direction_id = direction;
    // console.log(data);
    CreateOrder(data);
    history.push("/pedidos");
  };

  const [openAddDirection, setOpenAddDirection] = useState(false);
  const handleClickOpenAddDirection = () => {
    setOpenAddDirection(true);
  };
  const handleCloseAddDirection = () => {
    setOpenAddDirection(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <div
          style={{
            width: "100%",
            minWidth: "380px",
            height: 600,
            overflowY: "auto",
            padding: 5,
          }}
        >
          {cart.map((product) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              key={product.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { sx: "column", md: "row" },
                boxShadow: 5,
                padding: 1,
                marginBottom: 2,
                borderRadius: 5,
                backgroundColor: "#FEE3EC",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: 4,
                  }}
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <TextoCorto texto={product.name} maxLength={25} />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 3,
                  }}
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <input
                    //type="number"
                    //onChange={detectarCambiosCantidadComprada}
                    className={classes.jss59}
                    placeholder="Agrega la Cantidad Inicial"
                    name="quantityProduct"
                    min={1}
                    max={product.stock}
                    value={product.quantity}
                    onChange={(e) => handleChangeQuantity(e, product.id)}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 3,
                  }}
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                >
                  <Typography sx={{ color: "green", fontWeight: "bold" }}>
                    {product.stock}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 3,
                  }}
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <Typography> {product.price}</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 3,
                  }}
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <Typography> {product.quantity * product.price}</Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <IconButton onClick={() => handleClickDelete(product.id)}>
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </div>

        {cart.length > 0 && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: 1,
              }}
            >
              <Typography fontWeight="bold">Subtotal:</Typography>
              <Typography>$ {sumaSubtotal.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: 1,
              }}
            >
              <Typography fontWeight="bold">Descuento:</Typography>
              <Typography>{discount ? discount : 0} %</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: 1,
              }}
            >
              <Typography fontWeight="bold">Total:</Typography>
              <Typography>$ {total.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                  <SelectDirection
                    detectarCambiosDirection={detectarCambiosDirection}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{ justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleClickOpenAddDirection()}
                    sx={{
                      background: "#F999B7",
                      "&:hover": { background: "#F999B7" },
                    }}
                  >
                    Agregar dirección
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter")
                    e.preventDefault();
                }}
                autoComplete="off"
              >
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  fullWidth
                  sx={{
                    backgroundColor: "#F999B7",
                    color: "white",
                    "&:hover": { backgroundColor: "#F999B7", color: "white" },
                  }}
                >
                  Terminar compra
                </Button>
              </form>
            </Grid>
          </Grid>
        )}
        <AddDirection
          open={openAddDirection}
          handleClose={handleCloseAddDirection}
        />
      </Grid>
    </>
  );
};

export default TableProductsCart;
