import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Box, Button, ButtonGroup, Grid, IconButton } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import AddIcon from "@mui/icons-material/Add";
import ModalImage from "react-modal-image-responsive";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CartContext from "../../Context/Cart/CartContext";
import { useContext } from "react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import TextoCorto from "../../utils/TextoCorto";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "100%",
    border: "2px solid #F2789F  ",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    borderRadius: "5px",
    justifyContent: "center",
    justifyItems: "center",
  },
});
export default function CardProduct({ product }) {
  const classes = useStyles();
  const { img } = product;
  const miImagen = require("../../assets/img/default.webp");
  const [quantity, setQuantity] = useState(null);
  const [productId, setProductId] = useState(null);

  const handleChangeQuantity = (e, product_id) => {
    setQuantity(Number(e.target.value));
    setProductId(product_id);
  };

  const [debounceQuantity] = useDebounce(quantity, 1000);
  const data = {};
  const {
    AddProductCart,
    ReduceQuantityProduct,
    ModifyQuantityProduct,
    IncreaseQuantityProduct,
  } = useContext(CartContext);

  if (quantity && productId !== null) {
    data.quantity = quantity;
    data.product_id = productId;
  }

  useEffect(() => {
    if (quantity && productId !== null) {
      ModifyQuantityProduct(data);
    }
  }, [debounceQuantity]);

  return (
    <div className="plan">
      <div className="inner">
        <span class="pricing">
          <span>
            ${product.price_purchase * (1 - 0.4)} {"/ "}
            <small> $ {product.price_purchase}</small>
          </span>
        </span>
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <TextoCorto maxLength={30} texto={product.name} />
        </Box>
        {img?.length > 0 ? (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box sx={{ width: "100%", height: "50%", position: "static" }}>
                  <ModalImage
                    className="img"
                    small={img[0]?.image}
                    large={img[0]?.image}
                    alt={product.name}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{ width: "100%", height: "50%", position: "relative" }}
                >
                  <ModalImage
                    className="img"
                    small={miImagen}
                    large={miImagen}
                    alt={product.name}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <CardActions>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <ButtonGroup
                variant="text"
                size="small"
                aria-label="outlined primary button group"
              >
                {product.quantity === 1 ? (
                  <IconButton
                    onClick={() => ReduceQuantityProduct(product.id)}
                    disabled
                    sx={{ marginTop: -2 }}
                  >
                    <HorizontalRuleIcon
                      sx={{ color: "#E4097F", fontSize: "25px" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => ReduceQuantityProduct(product.id)}
                    sx={{ marginTop: -2 }}
                  >
                    <HorizontalRuleIcon
                      sx={{ color: "#E4097F", fontSize: "25px" }}
                    />
                  </IconButton>
                )}
                {product.quantity === 0 ? (
                  <input
                    //type="number"
                    //onChange={detectarCambiosCantidadComprada}
                    className={classes.jss59}
                    placeholder="Agrega la Cantidad Inicial"
                    name="quantityProduct"
                    min={0}
                    disabled
                    max={product.stock}
                    value={product.quantity}
                    onChange={(e) => handleChangeQuantity(e, product.id)}
                  />
                ) : (
                  <input
                    //type="number"
                    //onChange={detectarCambiosCantidadComprada}
                    className={classes.jss59}
                    placeholder="Agrega la Cantidad Inicial"
                    name="quantityProduct"
                    min={0}
                    max={product.stock}
                    value={product.quantity}
                    onChange={(e) => handleChangeQuantity(e, product.id)}
                  />
                )}
                {product.quantity === product.stock ? (
                  <IconButton
                    disabled
                    sx={{ color: "#E4097F", marginTop: -2 }}
                    onClick={() => AddProductCart(product.id)}
                  >
                    <AddIcon sx={{ color: "#E4097F", fontSize: "25px" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ color: "#E4097F", marginTop: -2 }}
                    onClick={() => AddProductCart(product.id)}
                  >
                    <AddIcon sx={{ color: "#E4097F", fontSize: "25px" }} />
                  </IconButton>
                )}
              </ButtonGroup>
            </Grid>
          </Grid>
        </CardActions>
      </div>
    </div>
  );
}
