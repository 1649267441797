import {
  GET_ALL_ORDERS_COMPLETED_USER,
  GET_ALL_ORDERS_PENDING_USER,
  UPLOAD_TICKET,
} from "../../types";

export default function PedidosReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ORDERS_PENDING_USER:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_ORDERS_COMPLETED_USER:
      return {
        ...state,
        orders_client: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case UPLOAD_TICKET:
      return {
        ...state,
        orders_client: state.orders_client.map((order) =>
          order.id === action.payload.id ? { ...action.payload } : order
        ),
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
