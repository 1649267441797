import React, { useContext, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import CategoriesContext from "../../Context/Categories/CategoriesContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Categories = () => {
  const { GetCategories, categories } = useContext(CategoriesContext);
  useEffect(() => {
    GetCategories();
  }, []);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Typography fontWeight="bold" textAlign="center" fontSize="35px">
            Categorias
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            {categories &&
              categories.map((category) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  key={category.id}
                >
                  <Link
                    to={`/productos/${category.name}/${category.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card sx={{ height: 194 }}>
                      <CardMedia
                        component="img"
                        height="194"
                        image={category.image}
                        alt={category.name}
                        sx={{ opacity: 0.5 }}
                      />
                      <Typography
                        sx={{
                          // position: "absolute",
                          mt: -15,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        textAlign="center"
                        fontWeight="bold"
                        fontSize="40PX"
                      >
                        {category.name}
                      </Typography>
                    </Card>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Categories;
