import { Grid } from "@mui/material";
import React from "react";

const Direction = ({ direction }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        Pais: {direction.country ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        Estado: {direction.state ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        Municipio: {direction.municipality ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        zona/barrio: {direction.zone ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        CP: {direction.postal_code ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        Calle: {direction.street ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        Entre calle: {direction.between_street ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        Y calle: {direction.and_between_street ?? "N/A"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        Referencias: {direction.references ?? "N/A"}
      </Grid>
    </Grid>
  );
};

export default Direction;
