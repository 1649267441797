import React from "react";
import AuthState from "./Context/auth/AuthState";
import AppRouter from "./routes/AppRouter";
import OrdersState from "./Context/Orders/OrdersState";
import ProductsState from "./Context/Products/ProductsState";
import CartState from "./Context/Cart/CartState";
import CategoriesState from "./Context/Categories/CategoriesState";
import PedidosState from "./Context/Pedidos/PedidosState";
import DirectionsState from "./Context/Directions/DirectionsState";
export default function CatalogoApp() {
  return (
    <AuthState>
      <CartState>
        <OrdersState>
          <CategoriesState>
            <ProductsState>
              <PedidosState>
                <DirectionsState>
                  <AppRouter />
                </DirectionsState>
              </PedidosState>
            </ProductsState>
          </CategoriesState>
        </OrdersState>
      </CartState>
    </AuthState>
  );
}
