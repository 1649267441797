import React, { useState, useContext, useEffect } from "react";
import CategoriesContext from "../../../Context/Categories/CategoriesContext";
import MethodGet from "../../../config/Service";
import { useForm } from "react-hook-form";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CircularProgress from "@mui/material/CircularProgress";

import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Swal from "sweetalert2";

//
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function EditCategory({ modal, handleClose, id }) {
  const classes = useStyles();
  const { updateCategory } = useContext(CategoriesContext);
  const [cargando, spinnerCargando] = useState(false);

  const [image, setImage] = useState({
    urlPhoto: require("../../../assets/img/default.webp"),
    image: "",
  });

  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const [category, saveCategory] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({ defaultValues: category });
  useEffect(() => {
    let url = `/Categories/${id}`;
    MethodGet(url)
      .then((res) => {
        saveCategory(res.data);
        console.log(res.data, "la data del show");
        setImage({ urlPhoto: res.data.image, image: "" });
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
        });
        spinnerCargando(true);
      });
  }, [id]);

  useEffect(() => {
    if (category) {
      setValue("name", category.name);
    } else {
      setValue("name", "");
    }
  }, [category]);

  const onSubmit = (data, e) => {
    data.id = id;
    data.image = image.image;
    // console.log(data, "la data que se envia");
    updateCategory(data);
    saveCategory(null);
    handleClose();
  };

  return (
    <Dialog open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Actualiza cartegoria</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      {!cargando ? (
        <CircularProgress />
      ) : (
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <img
                        src={image.urlPhoto}
                        className={classes.logoimagen}
                        alt="agrega marca"
                      />
                    </div>
                  </Box>

                  <input
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    name="image"
                    accept="image/png, image/jpg, image/webp"
                    onChange={handleChangeImage}
                  />
                  <InputLabel sx={{ textAlign: "center" }}>
                    Selecciona imagen de la categoria :
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen"
                        >
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                  </InputLabel>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    focused
                    fullWidth
                    id="name"
                    placeholder="Ejemplo: Liquidos"
                    name="name"
                    label="Nombre de la categoria"
                    variant="outlined"
                    type="text"
                    error={errors.name ? true : false}
                    helperText={errors?.name?.message}
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximo 255 caracteres",
                      },
                    })}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                    }}
                  >
                    <div className={classes.fab}>
                      <Fab
                        type="submit"
                        variant="extended"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}
                      >
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
}
