import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DirectionsContext from "../../Context/Directions/DirectionsContext";
import { useContext } from "react";
import { Grid } from "@mui/material";
export default function AddDirection({ open, modal, handleClose }) {
  const { storeDirection } = useContext(DirectionsContext);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const name_direction = formJson.name_direction;
            const country = formJson.country;
            const street = formJson.street;
            const between_street = formJson.between_street;
            const and_between_street = formJson.and_between_street;
            const number_int = formJson.number_int;
            const number_ext = formJson.number_ext;
            const state = formJson.state;
            const municipality = formJson.municipality;
            const city = formJson.city;
            const zone = formJson.zone;
            const postal_code = formJson.postal_code;
            const references = formJson.references;
            const data = {};
            data.name_direction = name_direction;
            data.street = street;
            data.country = country;
            data.between_street = between_street;
            data.and_between_street = and_between_street;
            data.number_int = number_int;
            data.number_ext = number_ext;
            data.state = state;
            data.municipality = municipality;
            data.city = city;
            data.zone = zone;
            data.postal_code = postal_code;
            data.references = references;
            storeDirection(data);
            handleClose();
          },
        }}
      >
        <DialogTitle>Agregar nueva dirección</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Registra una nueva dirección completando los datos
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label="Nombre de direccion"
                name="name_direction"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Pais" name="country" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Calle" name="street" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Entre calle" name="between_street" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                label="Y entre calle"
                name="and_between_street"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Numero Interior" name="number_int" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Numero ext." name="number_ext" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Estado" name="state" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Municipio" name="municipality" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Ciudad" name="city" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="zona / barrio" name="zone" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField fullWidth label="Codigo Postal" name="postal_code" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField fullWidth label="referencias" name="references" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" variant="contained">
            Cancelar
          </Button>
          <Button type="submit" color="success" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}