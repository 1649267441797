import React, { useEffect, useContext } from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import MethodGet from "../../config/Service";
import DirectionsContext from "../../Context/Directions/DirectionsContext";
export default function DirectionSelect(props) {
  const { directions, GetDirections } = useContext(DirectionsContext);
  useEffect(() => {
    GetDirections();
  }, []);
  const detectarCambiosDirection = (e) => {
    props.detectarCambiosDirection(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Direccion de envio
          </InputLabel>
          {props.directionId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosDirection}
              defaultValue={props.directionId}
            >
              {directions &&
                directions.map((directions) => (
                  <MenuItem key={directions.id} value={directions.id}>
                    {directions.direction}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosDirection}
            >
              {directions &&
                directions.map((directions) => (
                  <MenuItem key={directions.id} value={directions.id}>
                    {directions.street}, {directions.between_street},{" "}
                    {directions.zone}, {directions.municipality},{" "}
                    {directions.state}, {directions.postal_code},{" "}
                    {directions.country}
                  </MenuItem>
                ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
