import React from "react";
import { useReducer } from "react";
import CartReducer from "./CartReducer";
import CartContext from "./CartContext";
import {
  ADD_PRODUCT_CART,
  DELETE_PRODUCT,
  GET_CART_USER,
  GET_DISCOUNT,
  GET_TOTAL_CART,
  INCREASE_QUANTITY_PRODUCT_CART,
  MODIFY_QUANTITY_PRODUCT,
  REDUCE_QUANTITY_PRODUCT_CART,
} from "../../types";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import Swal from "sweetalert2";
const CartState = ({ children }) => {
  //estado inicial
  const initialState = {
    cart: [],
    totalCart: 0,
    success: false,
    ErrorsApi: [],
    discount: 0,
    id_business: 0,
  };
  //dispatch que ejecuta las acciones
  const [state, dispatch] = useReducer(CartReducer, initialState);

  //funcion para obtener el carrito del usuario
  const GetCartUserLogged = () => {
    let url = `/cart/show`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_CART_USER,
          payload: res.data.products,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //funcion para agregar un producto al carrito
  const AddProductCart = (id) => {
    let url = `/cart/add/${id}`;
    const formData = new FormData();
    formData.append("product_ids", id);
    MethodPost(url, formData)
      .then((res) => {
        dispatch({
          type: ADD_PRODUCT_CART,
          payload: res.data.products,
        });
        Swal.mixin({
          toast: true,
          background: "green",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `Agregado`,
          text: "El producto se ha agregado correctamente!",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Funcion para aumentar de +1 cantidad del producto
  const IncreaseQuantityProduct = (id) => {
    let url = `/cart/increase/${id}`;
    MethodPut(url)
      .then((res) => {
        dispatch({
          type: INCREASE_QUANTITY_PRODUCT_CART,
          payload: res.data,
        });
        Swal.mixin({
          toast: true,
          background: "green",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `Actualizado`,
          text: "Se ha actualizado la cantidad del producto",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //funcion para restar -1 en cantidad del producto
  const ReduceQuantityProduct = (id) => {
    let url = `/cart/decrease/${id}`;
    MethodPut(url)
      .then((res) => {
        dispatch({
          type: REDUCE_QUANTITY_PRODUCT_CART,
          payload: res.data,
        });
        Swal.mixin({
          toast: true,
          background: "green",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `Actualizado`,
          text: "Se ha actualizado la cantidad del producto",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //funcion para modificar la cantidad del producto
  const ModifyQuantityProduct = (data) => {
    let url = `/cart/update`;
    const formData = new FormData();
    formData.append("product_id", data.product_id);
    formData.append("quantity", Number(data.quantity));
    MethodPost(url, formData)
      .then((res) => {
        dispatch({
          type: MODIFY_QUANTITY_PRODUCT,
          payload: res.data,
        });
        Swal.mixin({
          toast: true,
          background: "green",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `Actualizado`,
          text: "Se ha actualizado la cantidad del producto",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //borrar producto del carrito
  const DeleteProductCart = (id) => {
    let url = `/cart/remove/${id}`;
    MethodDelete(url)
      .then((res) => {
        dispatch({
          type: DELETE_PRODUCT,
          payload: id,
        });
        Swal.mixin({
          toast: true,
          background: "orange",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `Eliminado`,
          text: "Se ha eliminado el producto del carrito",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetTotalCart = () => {
    let url = "/cart/total";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_TOTAL_CART,
          payload: res.data.total,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CalculateDiscount = (total) => {
    let url = `/Discount/${total}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_DISCOUNT,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <CartContext.Provider
      value={{
        cart: state.cart,
        success: state.success,
        ErrorsApi: state.ErrorsApi,
        totalCart: state.totalCart,
        products: state.products,
        discount: state.discount,
        id_business: state.id_business,
        GetCartUserLogged,
        AddProductCart,
        IncreaseQuantityProduct,
        ReduceQuantityProduct,
        DeleteProductCart,
        ModifyQuantityProduct,
        GetTotalCart,
        CalculateDiscount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartState;
