import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import ProductsContext from "../../Context/Products/ProductsContext";
import ProductCartNoAuth from "../../components/Products/ProductCartNoAuth";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Catalogo = () => {
  const { GetAllProductsNoAuth, products } = useContext(ProductsContext);
  useEffect(() => {
    GetAllProductsNoAuth();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ position: "sticky" }}
        >
          <Paper
            sx={{
              backgroundColor: "#F2789F",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/img/distri.png")}
              height={70}
              alt="logo_distri"
            />
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, margin: 2 }}>
        <AppBar position="static">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#f8bbd0",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                color: "#d81b60",
                fontSize: "25px",
              }}
            >
              Realizar tu pedido nunca fue tan fácil.
            </Typography>
            <Link to={"/iniciar-sesion"} sx={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  backgroundColor: "#ec407a",
                  color: "white",
                  borderColor: "#c2185b",
                  "&:hover": {
                    backgroundColor: "#ec407a",
                    color: "white",
                    borderColor: "#c2185b",
                  },
                }}
              >
                Iniciar
              </Button>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {products &&
          products.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <ProductCartNoAuth product={product} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default Catalogo;
