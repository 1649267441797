import React from "react";
import Layout from "../../components/Layout/Layout";
import { Grid, Typography } from "@mui/material";

const InicioAdmin = () => {
  return (
    <Layout>
      <Grid container spacing={2} sx={{ margin: 1 }}>
        <Grid item xs={12} sx={12} md={12} lg={12} xl={12}>
          <Typography textAlign="center" fontWeight="bold" fontSize="40px">
            Bienvenido!
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default InicioAdmin;
