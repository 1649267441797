import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CategoriesContext from "../../../Context/Categories/CategoriesContext";
import Default from "../../../assets/img/default.webp";
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    //boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function AddCategories(props) {
  const classes = useStyles();
  const { modal, handleClose } = props;
  const [brand, saveBrand] = useState();

  const [image, setImage] = useState({
    urlPhoto: require("../../../assets/img/default.webp"),
    image: "",
  });
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const { storeCategory, success } = useContext(CategoriesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const reset = () => {
    setValue("name", "", { shouldDirty: true });
    setValue("image", "", { shouldDirty: true });
    setValue("imageMovil", "", { shouldDirty: true });
    setImage({
      urlPhoto: require("../../../assets/img/default.webp"),
      image: "",
    });
  };

  const onSubmit = (data, e) => {
    data.image = image.image;
    if (data !== "") {
      storeCategory(data);
      reset();
      handleClose();
    } else {
      Swal.fire({
        title: "error",
        text: "Todos los campos son obligatorios",
        icon: "error",
      });
    }
  };

  return (
    <Dialog open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Agrega Categoria</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "6px" }}>
          Aqui podras agregar nuevas categorias para tus productos.
        </DialogContentText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
        >
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <img
                      src={image.urlPhoto}
                      className={classes.logoimagen}
                      alt="agrega categoria"
                    />
                  </div>
                </Box>
                <input
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  imagen :
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen"
                      >
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Nombre de la categoria"
                  placeholder="Ejemplo:Monomero M..."
                  variant="outlined"
                  name="name"
                  type="text"
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 4,
                      message: "Minimo 4 caracteres",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}
                >
                  <div className={classes.fab}>
                    <Fab
                      variant="extended"
                      type="submit"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}
                    >
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
