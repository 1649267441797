import * as React from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Button, CardContent, Typography } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import ModalImage from "react-modal-image-responsive";
import TextoCorto from "../../utils/TextoCorto";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
  jss59: {
    width: "20%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});

export default function ProductCartNoAuth({ product }) {
  const classes = useStyles();

  const { img } = product;
  const miImagen = require("../../assets/img/default.webp");

  return (
    <Card>
      <CardContent>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              backgroundColor: "#ec407a",
              padding: 1.5,
              width: "35%",
              marginRight: -2,
              justifyContent: "flex-end",
              borderTopLeftRadius: 25,
              borderBottomLeftRadius: 25,
            }}
          >
            <span
              textAlign="center"
              fontWeight="bold"
              style={{
                color: "#fff",
                textDecoration: "line-through",
                fontSize: 15,
              }}
            >
              ${product.price_purchase}
            </span>
            <span style={{ fontWeight: "bold", color: "#fff", fontSize: 20 }}>
              {""} / {""}
            </span>
            <span
              textAlign="center"
              fontWeight="bold"
              style={{ color: "#fff", fontSize: 20 }}
            >
              ${product.price_purchase * 0.6}
            </span>
          </Box>
        </div>
        <Typography noWrap sx={{ fontWeight: "bold", fontSize: "17px" }}>
          <TextoCorto texto={product.name} maxLength={32} />
        </Typography>
      </CardContent>
      {img.length > 0 ? (
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ position: "relative" }}>
                <ModalImage
                  className={classes.imgproduct}
                  small={img[0]?.image}
                  large={img[0]?.image}
                  alt={product.name}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ position: "relative" }}>
                <ModalImage
                  className={classes.imgproduct}
                  small={miImagen}
                  large={miImagen}
                  alt={product.name}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Card>
  );
}
