import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DirectionsContext from "../../Context/Directions/DirectionsContext";
import AddDirection from "./AddDirection";
import UpdateDirection from "./UpdateDirection";
const Directions = () => {
  const { GetDirections, directions, DeleteDirection } =
    useContext(DirectionsContext);
  useEffect(() => {
    GetDirections();
  }, []);
  /**open modal */
  const [openStore, setOpenStore] = useState(false);
  const handleClickOpenStore = () => {
    setOpenStore(true);
  };
  const handleClickCloseStore = () => {
    setOpenStore(false);
  };
  /**open modal update*/
  const [directionId, setDirectionId] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const handleClickOpenUpdate = (id) => {
    setOpenUpdate(true);
    setDirectionId(id);
  };
  const handleClickCloseUpdate = () => {
    setOpenUpdate(false);
    setDirectionId(null);
  };
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Typography fontWeight="bold" textAlign="center" fontSize="35px">
            Mis direcciones
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handleClickOpenStore}>
            {" "}
            Agregar{" "}
          </Button>
        </Grid>
        {directions !== null &&
          directions.map((dir) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card>
                <CardContent>
                  {dir.name_direction ?? "N/A"}
                  <div>
                    {dir.street}, {dir.between_street}, {dir.number_ext},{" "}
                    {dir.municipality}, {dir.state}, {dir.postal_code},{" "}
                    {dir.country}
                  </div>
                  <div>{dir.references}</div>
                </CardContent>
                <CardActions>
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <IconButton onClick={() => DeleteDirection(dir.id)}>
                      <DeleteIcon sx={{ color: "red" }} />
                    </IconButton>
                    <IconButton onClick={() => handleClickOpenUpdate(dir.id)}>
                      <EditIcon sx={{ color: "#42a5f5" }} />
                    </IconButton>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <AddDirection open={openStore} handleClose={handleClickCloseStore} />
      {directionId && (
        <UpdateDirection
          open={openUpdate}
          id={directionId}
          handleClose={handleClickCloseUpdate}
        />
      )}
    </Layout>
  );
};

export default Directions;
