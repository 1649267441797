function TextoCorto({ texto, maxLength }) {
  // Verificar si la longitud del texto es mayor que la longitud máxima permitida
  if (texto.length > maxLength) {
    // Si es mayor, truncar el texto y agregar puntos suspensivos al final
    texto = texto.substring(0, maxLength) + "...";
  }

  return <span className="title">{texto}</span>;
}

export default TextoCorto;
